export default {
  name: 'CourseIndex',
  data: function data() {
    return {
      name: '课程',
      breadList: []
    };
  },
  components: {},
  created: function created() {},
  mounted: function mounted() {
    this.getBreadCrumb();
  },
  methods: {
    //手动设置navigation
    navigation: function navigation(navData) {
      var _this = this;
      setTimeout(function () {
        // console.log("navigation",navData)
        _this.breadList = navData;
      });
    },
    getBreadCrumb: function getBreadCrumb() {
      var matched = this.$route.matched;
      // this.breadList=[{path:'/',meta:{title:'首页'}}].concat(matched)
      this.breadList = this.$route.matched;
      // console.log("this.breadlist",this.breadList)
      // console.log("this.childView",this.$refs.childView)
    }
  },

  watch: {
    $route: function $route() {
      this.getBreadCrumb();
    }
  }
};