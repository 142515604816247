import "core-js/modules/es.function.name.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-152fcdfd"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "main"
};
var _hoisted_2 = {
  class: "right_main"
};
var _hoisted_3 = {
  class: "right_main_inner"
};
var _hoisted_4 = {
  class: "right_content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_left_bar = _resolveComponent("left-bar");
  var _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item");
  var _component_el_breadcrumb = _resolveComponent("el-breadcrumb");
  var _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("section", _hoisted_1, [_createVNode(_component_left_bar, {
    itemName: $data.name
  }, null, 8, ["itemName"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_breadcrumb, {
    class: "breadcrumb",
    separator: "/"
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.breadList, function (item, key) {
        return _openBlock(), _createBlock(_component_el_breadcrumb_item, {
          to: {
            'path': item.path
          }
        }, {
          default: _withCtx(function () {
            return [_createTextVNode(_toDisplayString(item.meta.title), 1)];
          }),
          _: 2
        }, 1032, ["to"]);
      }), 256))];
    }),
    _: 1
  }), _createElementVNode("div", _hoisted_4, [_createVNode(_component_router_view, {
    onNavigation: $options.navigation
  }, null, 8, ["onNavigation"])])])])]);
}